import React, { useEffect } from 'react';
import Map from './components/GoogleMap/Map';

import { useDispatch, useSelector } from 'react-redux';
import { MapActions } from './store/map';
import { AutocompleteOptionType } from './models/AutocompleteOption';
import './App.css';
import { PageSelectors } from './store/page/page.selectors';
import { PageActions } from './store/page';
import headerDesktop from './data/page/header_desktop.json';
import headerMobile from './data/page/header_mobile.json';
import { Header } from './components/Header/Header';
import { Footer } from './components/Footer/Footer';
import { StudioActions } from './store/studio';
import categoriesDe from './data/partners/categories/de/partner_categories.json';
import categoriesEn from './data/partners/categories/en/partner_categories.json';
import studiosDe from './data/partners/de/partners.json';
import studiosEn from './data/partners/en/partners.json';
import { StudioSelectors } from './store/studio/studio.selectors';
import { getLanguage } from './utils/language.utils';
import { StudioModel } from './models/StudioModel';
import { isStudioValid } from './store/studio/studio.utils';
import { useIntl } from 'react-intl';
import { MapSelectors } from './store/map/map.selectors';

const App = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const desktopMenu = useSelector(PageSelectors.getDesktopMenu);
  const mobileMenu = useSelector(PageSelectors.getMobileMenu);
  const radius = useSelector(StudioSelectors.getRadiusFilterValue);
  const sort = useSelector(StudioSelectors.getSort);
  const filter = useSelector(StudioSelectors.getFilter);
  const myLocation = useSelector(MapSelectors.getMyLocation);

  // eslint-disable-next-line
  const setLocation = ({ coords: { latitude: lat, longitude: lng } }: GeolocationPosition) => {
    if (!myLocation) {
      dispatch(PageActions.toggleSidebar(true));
      dispatch(
        MapActions.setMyLocation({
          lat,
          lng,
          type: AutocompleteOptionType.CurrentLocation,
          name: intl.formatMessage({
            id: 'my_location',
            defaultMessage: 'Show my location on map',
          }),
        }),
      );

      dispatch(
        MapActions.setSelectedLocation({
          lat,
          lng,
          type: AutocompleteOptionType.CurrentLocation,
          name: intl.formatMessage({
            id: 'my_location',
            defaultMessage: 'Show my location on map',
          }),
        }),
      );

      dispatch(
        StudioActions.setQuery(
          intl.formatMessage({
            id: 'my_location',
            defaultMessage: 'Show my location on map',
          }),
        ),
      );

      dispatch(
        StudioActions.filterStudiosByQuery({
          query: '',
          type: AutocompleteOptionType.CurrentLocation,
          sort,
          radius,
          filter,
          selectedLocation: {
            lat,
            lng,
            type: AutocompleteOptionType.CurrentLocation,
            name: intl.formatMessage({
              id: 'my_location',
              defaultMessage: 'Show my location on map',
            }),
          },
        }),
      );

      dispatch(
        MapActions.setMapBounds({
          center: { lat, lng },
          distance: radius,
        }),
      );

      dispatch(StudioActions.setRadiusFilterDisabled(false));
    }
  };

  useEffect(() => {
    if (!desktopMenu) {
      dispatch(PageActions.setDesktopMenu(headerDesktop));
    }

    if (!mobileMenu) {
      dispatch(PageActions.setMobileMenu(headerMobile));
    }
  }, [desktopMenu, dispatch, mobileMenu]);

  useEffect(() => {
    const language = getLanguage();

    const studios = (language === 'de' ? studiosDe : studiosEn) as StudioModel[];
    const allVisibleStudios = studios.filter(studio => isStudioValid(studio));

    dispatch(StudioActions.setCategories(language === 'de' ? categoriesDe : categoriesEn));
    dispatch(StudioActions.setVisibleStudios(allVisibleStudios));
  }, [dispatch]);

  useEffect(() => {
    if (navigator.geolocation && !myLocation) {
      navigator.geolocation.getCurrentPosition(setLocation);
    }
  }, [dispatch, myLocation, setLocation]);

  useEffect(() => {
    const sidebarOpen = localStorage.getItem('sidebar-open');
    const open = typeof sidebarOpen !== 'object';

    dispatch(PageActions.toggleSidebar(open));
  }, [dispatch]);

  return (
    <>
      <Header />
      <Map />
      <Footer />
    </>
  );
};

export default App;
