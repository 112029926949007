import { AutocompleteOption, AutocompleteOptionType } from '../../models/AutocompleteOption';
import { StudioModel } from '../../models/StudioModel';

export const getPlacesPredictions = async (
  query: string,
  map: google.maps.Map,
  types: string[],
): Promise<AutocompleteOption[]> => {
  const google = window.google;
  const autocompleteService = new google.maps.places.AutocompleteService();

  const response = await autocompleteService.getPlacePredictions({
    input: query,
    language: 'de',
    types,
    componentRestrictions: { country: ['de', 'lu'] },
  });

  const placePredictions: AutocompleteOption[] = [];
  const predictions = response.predictions.slice(0, 4);

  predictions.forEach((prediction: google.maps.places.AutocompletePrediction) => {
    placePredictions.push({
      name: prediction.description,
      placeId: prediction.place_id,
      lat: 0,
      lng: 0,
      type: AutocompleteOptionType.Prediction,
    });
  });

  return Promise.resolve(placePredictions);
};

export const getPlaceDetail = (
  map: google.maps.Map,
  placeId: string,
): Promise<AutocompleteOption> => {
  return new Promise((resolve, reject) => {
    const placesService = new google.maps.places.PlacesService(map);
    placesService.getDetails({ placeId, language: 'de' }, (placeResult, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK && placeResult) {
        resolve({
          name: placeResult.formatted_address?.replace(', Deutschland', '')!,
          lat: placeResult.geometry?.location?.lat()!,
          lng: placeResult.geometry?.location?.lng()!,
          type:
            placeResult.types?.indexOf('locality') !== -1
              ? AutocompleteOptionType.City
              : AutocompleteOptionType.Street,
        });
      } else {
        reject(status);
      }
    });
  });
};

export const getStudioOptionsBySearchValue = (
  query: string,
  studios: StudioModel[],
): AutocompleteOption[] => {
  const filteredOptions: AutocompleteOption[] = [];
  studios.forEach(studio => {
    const { studioName, coordLat, cordLong, categoryPrimary, publicId } = studio;
    if (studioName.toLowerCase().includes(query.toLowerCase())) {
      filteredOptions.push({
        name: studioName,
        lat: coordLat,
        lng: cordLong,
        type: AutocompleteOptionType.Studio,
        categoryPrimary,
        publicId,
      });
    }
  });

  return filteredOptions.slice(0, 4);
};

export const getFilteredOptions = (query: string, options: AutocompleteOption[]) => {
  const filteredOptions: AutocompleteOption[] = [];

  if (query.length > 2) {
    options.forEach(option => {
      if (option.name.toLowerCase().includes(query.trim().replace(/.$/, '').toLowerCase())) {
        filteredOptions.push(option);
      }
    });
  }

  return filteredOptions.slice(0, 4);
};
