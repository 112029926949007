export interface AutocompleteOption {
  name: string;
  lat: number;
  lng: number;
  type: AutocompleteOptionType;
  categoryPrimary?: number;
  publicId?: string;
  placeId?: string;
}
export enum AutocompleteOptionType {
  City,
  Street,
  Studio,
  CurrentLocation,
  Prediction
}
