import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Marker, useGoogleMap } from '@react-google-maps/api';
import { StudioSelectors } from '../../store/studio/studio.selectors';
import { StudioModel } from '../../models/StudioModel';
import { StudioActions } from '../../store/studio';
import { MapSelectors } from '../../store/map/map.selectors';
import { MapActions } from '../../store/map';
import { AutocompleteOption, AutocompleteOptionType } from '../../models/AutocompleteOption';
import { getCategoryById } from '../../store/studio/studio.utils';
import { PageActions } from '../../store/page';

export const Markers = () => {
  const dispatch = useDispatch();
  const markersRef = useRef<Marker[]>([]);
  const map = useGoogleMap();
  const visibleStudios = useSelector(StudioSelectors.getVisibleStudios);
  const radius = useSelector(StudioSelectors.getRadiusFilterValue);
  const selectedStudio = useSelector(StudioSelectors.getSelected);
  const allCategories = useSelector(StudioSelectors.getCategories);
  const center = useSelector(MapSelectors.getCenter);
  const distance = useSelector(MapSelectors.getDistance);
  const selectedLocation = useSelector(MapSelectors.getSelectedLocation);
  const hover = useSelector(
    StudioSelectors.getHover,
    () => visibleStudios.length > 200 && !selectedLocation,
  );
  const isMobileView = window.innerWidth <= 768;
  const selectedStudioModalOpen = useSelector(StudioSelectors.getSelectedStudioModalOpen);

  const handleLocalMouseOver = (idx: number, studio: StudioModel) => {
    const marker = markersRef.current[idx];
    const category = getCategoryById(allCategories, studio.categoryPrimary);

    marker.marker?.setIcon({
      url: category.mapMarkerUrlSelected,
      scaledSize: new google.maps.Size(45, 45),
    });
  };

  const handleLocalMouseOut = (idx: number, studio: StudioModel) => {
    const marker = markersRef.current[idx];
    const isSelected = selectedStudio?.publicId === studio.publicId;
    const category = getCategoryById(allCategories, studio.categoryPrimary);
    marker.marker?.setIcon({
      url: isSelected ? category.mapMarkerUrlSelected : category.mapMarkerUrlActive,
      scaledSize: new google.maps.Size(40, 40),
    });
  };

  const handleLocalMouseClick = (idx: number, studio: StudioModel) => {
    const marker = markersRef.current[idx];
    const category = getCategoryById(allCategories, studio.categoryPrimary);
    marker.marker?.setIcon({
      url: category.mapMarkerUrlActive,
      scaledSize: new google.maps.Size(45, 45),
    });

    dispatch(StudioActions.selectStudio(studio));
    if (!selectedLocation || selectedLocation.type === AutocompleteOptionType.Studio) {
      dispatch(StudioActions.setQuery(studio.studioName));
    }

    dispatch(StudioActions.setRadiusFilterDisabled(false));
    dispatch(
      MapActions.setMapBounds({
        center: { lng: studio.cordLong, lat: studio.coordLat },
        distance: radius,
      }),
    );

    const newSelectedLocation: AutocompleteOption = {
      name: studio.studioName,
      lat: studio.coordLat,
      lng: studio.cordLong,
      type: AutocompleteOptionType.Studio,
    };
    if (selectedLocation?.type === AutocompleteOptionType.Studio || !selectedLocation) {
      dispatch(MapActions.setSelectedLocation(newSelectedLocation));
    }

    if (isMobileView && !selectedStudioModalOpen) {
      dispatch(StudioActions.setSelectedStudioModalOpen(true));
    }

    dispatch(PageActions.toggleSidebar(true));
  };

  useEffect(() => {
    if (center && distance) {
      const bounds = new window.google.maps.LatLngBounds();
      const east = window.google.maps.geometry.spherical.computeOffset(center, distance, 90);
      bounds.extend(east);
      const west = window.google.maps.geometry.spherical.computeOffset(center, distance, 270);
      bounds.extend(west);

      map?.fitBounds(bounds);
      map?.panTo(center);
    }
  }, [center, distance, map]);

  const isStudioValidForMarker = (studio: StudioModel) => {
    return null !== studio.coordLat && null !== studio.cordLong;
  };

  const google = window.google;

  return (
    <>
      {visibleStudios.map((studio, idx) => {
        if (isStudioValidForMarker(studio)) {
          const category = getCategoryById(allCategories, studio.categoryPrimary);
          const isHoverOrSelect =
            selectedStudio?.publicId === studio.publicId || hover === studio.publicId;
          return (
            <Marker
              zIndex={isHoverOrSelect ? 2 : 1}
              ref={el => (markersRef.current[idx] = el!)}
              onClick={() => handleLocalMouseClick(idx, studio)}
              onMouseOver={
                visibleStudios.length > 0 ? () => handleLocalMouseOver(idx, studio) : undefined
              }
              onMouseOut={
                visibleStudios.length > 0 ? () => handleLocalMouseOut(idx, studio) : undefined
              }
              key={studio.publicId}
              icon={{
                url: isHoverOrSelect ? category.mapMarkerUrlSelected : category.mapMarkerUrlActive,
                scaledSize: new google.maps.Size(
                  isHoverOrSelect ? 45 : 40,
                  isHoverOrSelect ? 44 : 40,
                ),
              }}
              position={{ lng: studio.cordLong as number, lat: studio.coordLat as number }}
            />
          );
        }

        return null;
      })}
    </>
  );
};
