import { styled } from '@mui/material';

export const Container = styled('div')`
  padding-top: 70px;
  height: calc(100% - 70px - 52px);
  width: 100%;
  position: absolute;
  
  ${props => props.theme.breakpoints.down('lg')} {
    padding-top: 163px;
    height: calc(100% - 163px - 52px);
  }
  
  ${props => props.theme.breakpoints.down('md')} {
    padding-top: 224px;
    height: calc(100% - 224px - 52px);
  }

  ${props => props.theme.breakpoints.down('sm')} {
    padding-top: 174px;
    height: calc(100% - 175px - 40px);
  }

`;
