import React from 'react';
import { FormControl, MenuItem, Radio, Select } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { selectionStyles } from '../../assets/styles/MUI/Styles';
import { useIntl } from 'react-intl';
import sortingIcon from '../../assets/icons/sorting.svg';
import { StudioActions } from '../../store/studio';
import { StudioSelectors } from '../../store/studio/studio.selectors';
import StudioSearchResults from '../StudioSearchResults/StudioSearchResults';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import {
  EmptyResultsContainer,
  EmptyResultsText,
  EmptyResultsTitle,
  Title,
  Container,
  Header,
} from './Selection.styles';
import { StudioSortType } from '../../store/studio/interfaces';
import { MapSelectors } from '../../store/map/map.selectors';
import NoResults from '../NoResults/NoResults';

const Selection = () => {
  const visibleStudios = useSelector(StudioSelectors.getVisibleStudios);
  const sort = useSelector(StudioSelectors.getSort);
  const selectedLocation = useSelector(MapSelectors.getSelectedLocation);
  const myLocation = useSelector(MapSelectors.getMyLocation);
  const dispatch = useDispatch();
  const intl = useIntl();
  const classes = selectionStyles();

  const handleSortFilterChange = (event: SelectChangeEvent) => {
    if (selectedLocation) {
      const sort = event.target.value as unknown as StudioSortType;
      dispatch(StudioActions.setSort(sort));
      dispatch(StudioActions.sortStudios({ sort, selectedLocation }));
    }
  };

  return (
    <>
      {visibleStudios.length > 0 && !!selectedLocation && (
        <Container hasLocation={!!myLocation}>
          <Header>
            <Title>
              {intl.formatMessage({
                id: 'selection.title',
                defaultMessage: 'Your Selection',
              })}
            </Title>
            <FormControl className={classes.formControl}>
              <div>
                <img src={sortingIcon} alt={'Sorting'} />
              </div>
              <Select
                variant="standard"
                value={sort as unknown as string}
                renderValue={value => {
                  return (value as unknown as StudioSortType) === StudioSortType.Distance
                    ? intl.formatMessage({ id: 'sort.distance', defaultMessage: 'Distance' })
                    : intl.formatMessage({ id: 'sort.alphabetical', defaultMessage: 'A - Z' });
                }}
                onChange={handleSortFilterChange}>
                <MenuItem value={StudioSortType.Distance} key={StudioSortType.Distance}>
                  <Radio checked={sort === StudioSortType.Distance} size="small" />
                  {intl.formatMessage({ id: 'sort.distance', defaultMessage: 'Distance' })}
                </MenuItem>
                <MenuItem value={StudioSortType.Alphabetical} key={StudioSortType.Alphabetical}>
                  <Radio checked={sort === StudioSortType.Alphabetical} size="small" />
                  {intl.formatMessage({ id: 'sort.alphabetical', defaultMessage: 'A - Z' })}
                </MenuItem>
              </Select>
            </FormControl>
          </Header>

          <StudioSearchResults />
        </Container>
      )}

      {visibleStudios.length === 0 && !!selectedLocation && (
        <EmptyResultsContainer>
          <EmptyResultsTitle>
            {intl.formatMessage({
              id: 'selection.empty_results_title',
              defaultMessage: 'Find our partners in your area',
            })}
          </EmptyResultsTitle>
          <EmptyResultsText>
            {intl.formatMessage({
              id: 'selection.empty_results_subtitle',
              defaultMessage:
                'Enter your address or directly the name of a partner and get information for them.',
            })}
          </EmptyResultsText>
        </EmptyResultsContainer>
      )}

      {visibleStudios.length === 0 && !selectedLocation && <NoResults />}
    </>
  );
};

export default Selection;
