import React, { useCallback, useEffect, useState } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import defaultIcon from '../../assets/map_marker/blue_marker_icon.png';
import cityIcon from '../../assets/map_marker/red_flag_icon.png';
import { useDispatch, useSelector } from 'react-redux';
import { MarkersContainer } from './MarkersContainer';
import { StudioActions } from '../../store/studio';
import { MapActions } from '../../store/map';
import { MapSelectors } from '../../store/map/map.selectors';
import { StudioSelectors } from '../../store/studio/studio.selectors';
import { AutocompleteOption, AutocompleteOptionType } from '../../models/AutocompleteOption';
import Sidebar from '../Sidebar/Sidebar';
import SelectedStudioModal from '../SelectedStudioModal/SelectedStudioModal';
import { Container } from './Map.styles';

const google = window.google;
const mapOptions = {
  disableDefaultUI: true,
  zoomControl: true,
  zoomControlOptions: { position: 9 },
  mapTypeId: google.maps.MapTypeId.ROADMAP,
  minZoom: 5,
  maxZoom: 18,
};

const containerStyles = {
  width: '100%',
  height: '100%',
  overflow: 'scroll',
};
const defaultZoom = 7;
let defaultCenter = {
  lat: 50.714504,
  lng: 10.101328,
};

export const Map = () => {
  const dispatch = useDispatch();
  const studios = useSelector(StudioSelectors.getVisibleStudios);
  const selectedLocation = useSelector(MapSelectors.getSelectedLocation);
  const myLocation = useSelector(MapSelectors.getMyLocation);
  const [markedLocation, setMarkedLocation] = useState<AutocompleteOption | null>(null);
  const [isMounted, setIsMounted] = useState(false);
  const isMobileView = window.innerWidth <= 768;
  const selectedStudioModalOpen = useSelector(StudioSelectors.getSelectedStudioModalOpen);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const filterStudiosByLocation = useCallback(
    (distance = 100) => {
      if (0 === studios.length && distance < 100) {
        filterStudiosByLocation(distance + 15);
      } else {
        dispatch(StudioActions.setRadiusFilterValue(distance));
        if (selectedLocation) {
          dispatch(
            MapActions.setMapBounds({
              center: { lat: selectedLocation.lat, lng: selectedLocation.lng },
              distance: distance,
            }),
          );
        }
      }
    },
    [selectedLocation, dispatch, studios.length],
  );

  const onLoad = useCallback((map: google.maps.Map) => {
    map.setCenter(defaultCenter);
    map.setZoom(defaultZoom);
  }, []);

  useEffect(() => {
    if (
      selectedLocation &&
      markedLocation !== selectedLocation &&
      (selectedLocation.type === AutocompleteOptionType.City ||
        selectedLocation.type === AutocompleteOptionType.Street)
    ) {
      setMarkedLocation(selectedLocation);
    }

    if (!selectedLocation && markedLocation) {
      setMarkedLocation(null);
    }
  }, [markedLocation, selectedLocation]);

  return (
    <Container>
      <GoogleMap
        mapContainerStyle={containerStyles}
        options={mapOptions}
        onLoad={onLoad}
        id={'map'}>
        {myLocation && (
          <Marker
            zIndex={2}
            key={'my-location'}
            icon={{ url: defaultIcon, scaledSize: new google.maps.Size(35, 35) }}
            position={{ lng: myLocation.lng, lat: myLocation.lat }}
          />
        )}
        {/*current selected city marker*/}
        {selectedLocation && selectedLocation.type !== AutocompleteOptionType.CurrentLocation && (
          <Marker
            zIndex={3}
            key={'location'}
            icon={{ url: cityIcon, scaledSize: new google.maps.Size(20, 20) }}
            position={{ lng: selectedLocation.lng, lat: selectedLocation.lat }}
          />
        )}

        {/*partners*/}
        {isMounted && <MarkersContainer />}
        <Sidebar />
        {isMobileView && selectedStudioModalOpen && <SelectedStudioModal />}
      </GoogleMap>
    </Container>
  );
};

export default Map;
