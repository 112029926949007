import React, { SyntheticEvent, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useIntl } from 'react-intl';
import debounce from 'lodash.debounce';
import sidebarOpenIcon from '../../assets/icons/atoms-cta-back.svg';
import searchIcon from '../../assets/icons/atoms-icons-regular-search.svg';
import searchMarkerIcon from '../../assets/icons/search-marker.svg';
import filterIconOpen from '../../assets/icons/atoms-icons-filter-active.svg';
import { InputAdornment, Tab, TextField, useMediaQuery, useTheme } from '@mui/material';
import {
  ActiveFilter,
  FilterButton,
  FilterContainer,
  FilterForm,
  FilterIcon,
  FilterRow,
  FilterTitle,
  MobileTabs,
  SidebarContainer,
  StyledAutocomplete,
  ToggleButton,
  TopContainer,
} from './Sidebar.styles';
import { useDispatch, useSelector } from 'react-redux';
import { StudioActions } from '../../store/studio';
import { MapActions } from '../../store/map';
import { StudioSelectors } from '../../store/studio/studio.selectors';
import LocationButton from '../StudioSearchResults/LocationButton';
import { FilterModal } from '../FilterModal/FilterModal';
import Selection from '../Selection/Selection';
import {
  getFilteredOptions,
  getPlaceDetail,
  getPlacesPredictions,
  getStudioOptionsBySearchValue,
} from './Sidebar.utils';
import { AutocompleteOption, AutocompleteOptionType } from '../../models/AutocompleteOption';
import { MapSelectors } from '../../store/map/map.selectors';
import { useGoogleMap } from '@react-google-maps/api';
import { initialState } from '../../store/studio/initialState';
import { StudioModel } from '../../models/StudioModel';
import { getCategoryById, isStudioValid } from '../../store/studio/studio.utils';
import studiosDe from '../../data/partners/de/partners.json';
import studiosEn from '../../data/partners/en/partners.json';
import Slider from '../Slider/Slider';
import { getLanguage } from '../../utils/language.utils';
import { PageSelectors } from '../../store/page/page.selectors';
import { PageActions } from '../../store/page';

const Sidebar = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const theme = useTheme();
  const studios = useSelector(StudioSelectors.getVisibleStudios);
  const [mobileTabValue, setMobileTabValue] = useState<number>(0);
  const [options, setOptions] = useState<AutocompleteOption[]>([]);
  const [isFiltersModuleActive, setIsFiltersModuleActive] = useState<boolean>(false);
  const radius = useSelector(StudioSelectors.getRadiusFilterValue);
  const radiusFilterDisabled = useSelector(StudioSelectors.getRadiusFilterDisabled);
  const map = useGoogleMap();
  const query = useSelector(StudioSelectors.getSearchQuery);
  const allCategories = useSelector(StudioSelectors.getCategories);
  const sort = useSelector(StudioSelectors.getSort);
  const filter = useSelector(StudioSelectors.getFilter);
  const filterTouched = JSON.stringify(filter) !== JSON.stringify(initialState.filter);
  const selectedLocation = useSelector(MapSelectors.getSelectedLocation);
  const myLocation = useSelector(MapSelectors.getMyLocation);
  const sidebarOpen = useSelector(PageSelectors.getSidebar);
  const toggleIcon = sidebarOpen ? sidebarOpenIcon : sidebarOpenIcon;
  const isSmall = useMediaQuery(theme.breakpoints.down('lg'));
  const autoCompleteRef = useRef<HTMLElement>(null);

  const activeFilter =
    filter.servicePackages.length +
    filter.categories.length +
    (filter.checkInCard ? 1 : 0) +
    (filter.checkInApp ? 1 : 0) +
    (filter.hansefitCard ? 1 : 0);
  const showList = filterTouched || !radiusFilterDisabled || studios.length === 0;

  const handleToggle = () => {
    if (sidebarOpen) {
      dispatch(PageActions.toggleSidebar(false));
      localStorage.removeItem('sidebar-open');
    } else {
      dispatch(PageActions.toggleSidebar(true));
      localStorage.setItem('sidebar-open', 'true');
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const element = e.target as HTMLInputElement;
      const filterOptions = getFilteredOptions(element.value as string, options);

      if (filterOptions.length > 0 && selectedLocation) {
        dispatch(
          StudioActions.filterStudiosByQuery({
            query: filterOptions[0].name,
            type: filterOptions[0].type,
            sort,
            radius,
            filter,
            selectedLocation,
          }),
        );

        dispatch(StudioActions.setRadiusFilterDisabled(false));
        dispatch(StudioActions.setQuery(filterOptions[0].name));
        e.preventDefault();
      } else {
        dispatch(StudioActions.setQuery(element.value as string));
        dispatch(StudioActions.setVisibleStudios([]));
        dispatch(MapActions.setSelectedLocation(null));
        e.preventDefault();
      }
    }
  };
  const handleFocus = () => {
    if (myLocation && options.length === 0) {
      const myLocationAutoCompleteOption: AutocompleteOption = {
        name: myLocation.name,
        lat: myLocation.lat,
        lng: myLocation.lng,
        type: AutocompleteOptionType.CurrentLocation,
      };
      setOptions([myLocationAutoCompleteOption]);
    }
  };
  const handleSearchQueryChange = async (
    e: SyntheticEvent<Element, Event>,
    query: string,
    reason: string,
  ) => {
    if ((query === '' && reason === 'clear') || (query === '' && reason === 'input')) {
      clearInputValue();
      if (myLocation) {
        const myLocationAutoCompleteOption: AutocompleteOption = {
          name: myLocation.name,
          lat: myLocation.lat,
          lng: myLocation.lng,
          type: AutocompleteOptionType.CurrentLocation,
        };
        setOptions([myLocationAutoCompleteOption]);
      } else {
        setOptions([]);
      }
    } else if (query !== '' && reason === 'input' && map) {
      dispatch(StudioActions.setQuery(query));

      if (query.length >= 3) {
        debounceSearchQueryChange.current(query);
      }

      if (query.length < 3 && myLocation) {
        const myLocationAutoCompleteOption: AutocompleteOption = {
          name: myLocation.name,
          lat: myLocation.lat,
          lng: myLocation.lng,
          type: AutocompleteOptionType.CurrentLocation,
        };
        setOptions([myLocationAutoCompleteOption]);
      }
    }
  };

  const handlePredictions = async (searchValue: string) => {
    if (map) {
      const predictions = await Promise.all(
        await getPlacesPredictions(searchValue, map, [
          'locality',
          'route',
          'street_address',
          'postal_code',
        ]),
      );

      const filteredStudios = getStudioOptionsBySearchValue(searchValue, studios);

      setOptions([...predictions, ...filteredStudios]);
    }
  };

  const debounceSearchQueryChange = useRef(debounce(handlePredictions, 500));

  const clearInputValue = () => {
    dispatch(StudioActions.clearSearchFilters());
    dispatch(StudioActions.setRadiusFilterDisabled(true));
    dispatch(MapActions.setSelectedLocation(null));
    dispatch(StudioActions.unselectStudio());
    dispatch(StudioActions.setRadiusFilterValue(100));

    const isFilterSet = JSON.stringify(filter) !== JSON.stringify(initialState.filter);

    if (isFilterSet) {
      dispatch(
        StudioActions.filterStudiosByQuery({
          query: '',
          type: AutocompleteOptionType.Studio,
          sort,
          radius,
          filter,
          selectedLocation: undefined,
        }),
      );
    } else {
      const language = getLanguage();
      const studios = (language === 'de' ? studiosDe : studiosEn) as StudioModel[];
      const allVisibleStudios = studios.filter(studio => isStudioValid(studio));

      dispatch(StudioActions.setVisibleStudios(allVisibleStudios));
    }
  };

  const handleOptionSelected = async (e: SyntheticEvent<Element, Event>, value: unknown) => {
    const resolvedOption =
      typeof value !== 'string'
        ? (value as AutocompleteOption)
        : getFilteredOptions(value as string, options)[0];

    let autocompleteOption: AutocompleteOption | undefined = undefined;

    if (resolvedOption) {
      const { type, placeId } = resolvedOption;

      if (placeId && map) {
        autocompleteOption = await getPlaceDetail(map, placeId);
      } else {
        autocompleteOption = resolvedOption;
      }

      dispatch(StudioActions.setQuery(autocompleteOption.name));
      dispatch(MapActions.setSelectedLocation(autocompleteOption));
      dispatch(
        MapActions.setMapBounds({
          center: { lng: autocompleteOption.lng, lat: autocompleteOption.lat },
          distance: radius,
        }),
      );
      dispatch(StudioActions.setRadiusFilterDisabled(false));
      dispatch(StudioActions.unselectStudio());

      if (
        autocompleteOption &&
        autocompleteOption.type === AutocompleteOptionType.Studio &&
        autocompleteOption.publicId
      ) {
        dispatch(StudioActions.filterStudioById({ publicId: autocompleteOption.publicId }));

        const studio = studios.find(studio => studio.publicId === autocompleteOption?.publicId);

        if (studio) {
          dispatch(StudioActions.selectStudio(studio));
        }
      } else {
        dispatch(
          StudioActions.filterStudiosByQuery({
            query,
            type,
            sort,
            radius,
            filter,
            selectedLocation: autocompleteOption,
          }),
        );
      }
    } else if (typeof resolvedOption !== 'undefined') {
      clearInputValue();
    }

    if (autoCompleteRef.current) {
      // autoCompleteRef.current.blur();
    }
  };

  const toggleFilterModal = () => {
    if (isFiltersModuleActive) {
      setIsFiltersModuleActive(false);
      localStorage.setItem('sidebar-filters-modal', 'true');
    } else {
      setIsFiltersModuleActive(true);
      localStorage.removeItem('sidebar-filters-modal');
    }
  };

  return (
    <SidebarContainer
      $isOpen={isSmall ? true : sidebarOpen}
      $showList={showList}
      $listTabOpen={Boolean(mobileTabValue)}
      $hasLocation={!!myLocation}>
      <FilterContainer>
        <TopContainer>
          <FilterTitle>
            {intl.formatMessage({
              id: 'sidebar.title',
              defaultMessage: 'Find Studios:',
            })}
          </FilterTitle>
          <ToggleButton onClick={handleToggle} $isOpen={sidebarOpen}>
            <img src={toggleIcon} alt="" />
          </ToggleButton>
        </TopContainer>
        <FilterForm $isOpen={isSmall ? true : sidebarOpen}>
          <FilterRow>
            <StyledAutocomplete
              blurOnSelect="touch"
              ref={autoCompleteRef}
              includeInputInList
              freeSolo
              disablePortal
              size="small"
              inputValue={query}
              onFocus={handleFocus}
              onInputChange={handleSearchQueryChange}
              onChange={handleOptionSelected}
              filterOptions={x => x}
              options={[...options]}
              getOptionLabel={(option: unknown) => {
                if (typeof option === 'string') {
                  return option;
                } else {
                  return (option as AutocompleteOption).name;
                }
              }}
              onKeyDown={handleKeyDown}
              renderOption={(props, option: any) => {
                const category = getCategoryById(
                  allCategories,
                  (option as StudioModel).categoryPrimary,
                );
                const studio = option as StudioModel;
                const id = uuidv4();

                if (studio.categoryPrimary) {
                  return (
                    <li {...props} className={props.className + ' autocomplete-option'} key={id}>
                      <div className={'option-partner-category-icon'}>
                        <img src={category.iconUrlWithoutCircle} alt={''} />
                      </div>
                      {option.name}
                    </li>
                  );
                }

                return (
                  <li {...props} className={props.className + ' autocomplete-option'} key={id}>
                    <img src={searchMarkerIcon} alt={''} />
                    {option.name}
                  </li>
                );
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  placeholder={intl.formatMessage({
                    id: 'sidebar.search_placeholder',
                    defaultMessage: 'Address, City or PLZ',
                  })}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <>
                        <InputAdornment sx={{ paddingLeft: '9px' }} position="start">
                          <img src={searchIcon} alt="" />
                        </InputAdornment>
                        {params.InputProps.startAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />

            <FilterButton onClick={toggleFilterModal}>
              {activeFilter > 0 && <ActiveFilter>{activeFilter}</ActiveFilter>}
              <FilterIcon alt="" src={filterIconOpen} />
            </FilterButton>
          </FilterRow>
          <FilterRow>
            <Slider />
          </FilterRow>
          {!isSmall && myLocation && (
            <FilterRow>
              <LocationButton />
            </FilterRow>
          )}
        </FilterForm>
      </FilterContainer>

      {!isSmall && sidebarOpen && showList && <Selection />}
      {isSmall && (
        <>
          <MobileTabs
            value={mobileTabValue}
            onChange={(event, value) => setMobileTabValue(value)}
            variant="fullWidth">
            <Tab
              label={intl.formatMessage({
                id: 'tabs.map_view',
                defaultMessage: 'Map view',
              })}
              value={0}
            />
            <Tab
              label={intl.formatMessage({
                id: 'tabs.list_view',
                defaultMessage: 'List view',
              })}
              value={1}
            />
          </MobileTabs>
          {mobileTabValue === 1 ? <Selection /> : <></>}
        </>
      )}

      {isFiltersModuleActive && <FilterModal closeModal={toggleFilterModal} />}
    </SidebarContainer>
  );
};

export default Sidebar;
