import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { Menus, PageState } from './interfaces';

const setDesktopMenu: CaseReducer<PageState, PayloadAction<Menus>> = (state, action) => {
  state.menu.desktop = action.payload;
};

const setMobileMenu: CaseReducer<PageState, PayloadAction<Menus>> = (state, action) => {
  state.menu.mobile = action.payload;
};

const toggleSidebar: CaseReducer<PageState, PayloadAction<boolean>> = (state, action) => {
  state.sidebar.open = action.payload;
};

export const pageSlice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    setDesktopMenu,
    setMobileMenu,
    toggleSidebar,
  },
});

export const { name, actions } = pageSlice;

export default pageSlice.reducer;
