import React from 'react';
import {
  Container,
  GeneralInfo,
  Separator,
  SocialMedia,
  StyledFooter,
  StyledLink,
  StyledSocialMediaIcon,
  StyledSocialMediaLink,
  StyledText,
} from './Footer.styles';
import { faFacebook, faInstagram, faLinkedinIn, faXing } from '@fortawesome/free-brands-svg-icons';

export const Footer = () => {
  return (
    <StyledFooter>
      <Container>
        <GeneralInfo>
          <StyledText variant={'body1'}>© 2010 - {new Date().getFullYear()}</StyledText>
          <Separator>|</Separator>
          <StyledLink href={'https://www.hansefit.de/impressum'}>Impressum</StyledLink>
          <Separator>|</Separator>
          <StyledLink href={'https://www.hansefit.de/datenschutz'}>Datenschutz</StyledLink>
        </GeneralInfo>

        <SocialMedia>
          <StyledSocialMediaLink href="https://www.linkedin.com/company/hansefit">
            <StyledSocialMediaIcon icon={faLinkedinIn} />
          </StyledSocialMediaLink>
          <StyledSocialMediaLink href="https://www.xing.com/pages/hansefitgmbh-co-kg">
            <StyledSocialMediaIcon icon={faXing} />
          </StyledSocialMediaLink>
          <StyledSocialMediaLink href="https://www.instagram.com/hansefit">
            <StyledSocialMediaIcon icon={faInstagram} />
          </StyledSocialMediaLink>
          <StyledSocialMediaLink href="https://www.facebook.com/Hansefit">
            <StyledSocialMediaIcon icon={faFacebook} />
          </StyledSocialMediaLink>
        </SocialMedia>
      </Container>
    </StyledFooter>
  );
};
