import React, { CSSProperties, useEffect, useRef } from 'react';
import categoriesDe from '../../data/partners/categories/de/partner_categories.json';
import categoriesEn from '../../data/partners/categories/en/partner_categories.json';
// @ts-ignore
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList as List } from 'react-window';
import searchMarkerIcon from '../../assets/icons/search-marker.svg';
import { StudioModel } from '../../models/StudioModel';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { StudioActions } from '../../store/studio';
import { MapActions } from '../../store/map';
import { StudioSelectors } from '../../store/studio/studio.selectors';
import {
  Address,
  CategoryImage,
  ColumnContainer,
  Container,
  Details,
  DetailsButton,
  DistanceContainer,
  Divider,
  LeftColumn,
  MarkerImage,
  RelativeContainer,
  RightColumn,
  Row,
  StudioContainer,
  StudioName,
  Tags,
} from './StudioSearchresults.styles';
import { getCategoryById, getResolvedCategories } from '../../store/studio/studio.utils';
import { MapSelectors } from '../../store/map/map.selectors';
import { getLanguage } from '../../utils/language.utils';
import { useMediaQuery, useTheme } from '@mui/material';
import {AutocompleteOptionType} from "../../models/AutocompleteOption";

interface SearchResultRowProps {
  index: number;
  style: CSSProperties;
}
export const StudioSearchResults = () => {
  const listRef = useRef<List | null>(null);
  const rowHeights = useRef<{ [key: string]: number }>({});
  const intl = useIntl();
  const dispatch = useDispatch();
  const visibleStudios = useSelector(StudioSelectors.getVisibleStudios);
  const hover = useSelector(StudioSelectors.getHover);
  const selectedStudio = useSelector(StudioSelectors.getSelected);
  const radius = useSelector(StudioSelectors.getRadiusFilterValue);
  const selectedLocation = useSelector(MapSelectors.getSelectedLocation);
  const accuracy: string = 'km';

  const handleCardSelect = (studio: StudioModel) => {
    dispatch(StudioActions.selectStudio(studio));
    dispatch(StudioActions.setRadiusFilterDisabled(false));
    dispatch(
      MapActions.setMapBounds({
        center: { lng: studio.cordLong, lat: studio.coordLat },
        distance: radius,
      }),
    );
  };

  const handleMouseEnter = (studio: StudioModel) => {
    dispatch(StudioActions.setHover(studio.publicId));
  };

  const handleMouseLeave = () => {
    dispatch(StudioActions.setHover(null));
  };

  const getRowHeight = (index: number) => {
    return rowHeights.current[index] ? rowHeights.current[index] : 100;
  };

  const setRowHeight = (index: number, size: number) => {
    listRef.current?.resetAfterIndex(0);
    rowHeights.current = { ...rowHeights.current, [index]: size };
  };

  useEffect(() => {
    setTimeout(() => {
      const index = visibleStudios.findIndex(
        visibleStudio => visibleStudio.publicId === selectedStudio?.publicId,
      );

      if (index !== -1) {
        listRef.current?.resetAfterIndex(index);
        listRef.current?.scrollToItem(index, 'start');
      }
    });
  }, [selectedStudio, visibleStudios]);

  const SearchResultRow = ({ index, style }: SearchResultRowProps) => {
    const allCategories = useSelector(StudioSelectors.getCategories);
    const theme = useTheme();
    const isLarge = useMediaQuery(theme.breakpoints.down('lg'));
    const rowRef = useRef<HTMLDivElement | null>(null);
    const studio = visibleStudios[index];
    const language = getLanguage();
    const categories = getResolvedCategories(
      studio,
      language === 'de' ? categoriesDe : categoriesEn,
    );
    const category = getCategoryById(allCategories, studio.categoryPrimary);
    const selectedStudio = useSelector(StudioSelectors.getSelected);

    useEffect(() => {
      if (rowRef.current) {
        setRowHeight(index, rowRef.current?.clientHeight + 1);
      }
    }, [index, rowRef]);

    return (
      <div style={style}>
        <StudioContainer
          ref={rowRef}
          $hover={hover === studio.publicId || selectedStudio?.publicId === studio.publicId}
          onClick={() => handleCardSelect(studio)}
          onMouseEnter={() => handleMouseEnter(studio)}
          onMouseLeave={handleMouseLeave}>
          <ColumnContainer>
            <Row>
              <LeftColumn>
                <CategoryImage
                  src={
                    category.iconUrlCircle
                      ? category.iconUrlCircle
                      : process.env.REACT_APP_ICON_URL_CIRCLE_FALLBACK
                  }
                  alt={'Fitness'}
                />
              </LeftColumn>
              <RightColumn>
                <StudioName
                  href={`${process.env.REACT_APP_HANSEFIT_WEBSITE}${studio.path}`}
                  target={isLarge ? '_self' : '__blank'}
                  rel="noopener noreferrer"
                  $hover={
                    hover === studio.publicId || selectedStudio?.publicId === studio.publicId
                  }>
                  {studio.studioName}
                </StudioName>
                <Address>
                  {studio.street} {studio.streetNumber},
                </Address>
                <Address>
                  {studio.zip} {studio.city}
                </Address>
              </RightColumn>
            </Row>

            <Row>
              <LeftColumn>
                <RelativeContainer>
                  {selectedLocation &&
                      (selectedLocation.type !== AutocompleteOptionType.Studio) &&
                      (studio.distanceFromPosition || studio.distanceFromPosition === 0) && (
                      <DistanceContainer>
                        <MarkerImage alt={''} src={searchMarkerIcon} />{' '}
                        {studio.distanceFromPosition} {accuracy}
                      </DistanceContainer>
                    )}
                </RelativeContainer>
              </LeftColumn>
              <RightColumn>
                <Details>
                  {categories && <Tags>{categories}</Tags>}
                  <DetailsButton
                    $hover={
                      hover === studio.publicId || selectedStudio?.publicId === studio.publicId
                    }
                    color={'primary'}
                    variant={'contained'}
                    href={`${process.env.REACT_APP_HANSEFIT_WEBSITE}${studio.path}`}
                    //  @ts-ignore
                    target={isLarge ? '_self' : '__blank'}
                    rel="noopener noreferrer">
                    {intl.formatMessage({
                      id: 'details_btn',
                      defaultMessage: 'Details',
                    })}
                  </DetailsButton>
                </Details>
              </RightColumn>
            </Row>
          </ColumnContainer>
        </StudioContainer>
        <Divider />
      </div>
    );
  };

  return (
    <Container>
      <AutoSizer>
        {({ height, width }: { height: number; width: number }) => {
          return (
            <List
              width={width}
              height={height}
              itemSize={getRowHeight}
              itemCount={visibleStudios.length}
              ref={listRef}>
              {SearchResultRow}
            </List>
          );
        }}
      </AutoSizer>
    </Container>
  );
};
export default StudioSearchResults;
