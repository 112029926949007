import { StudioSortType, StudioState } from './interfaces';

export const initialState: StudioState = {
  visibleStudios: [],
  categories: [],
  query: '',
  radiusFilterDisabled: true,
  radiusFilterValue: 100,
  hover: null,
  selected: null,
  selectedStudioModalOpen: true,
  sort: StudioSortType.Distance,
  filter: {
    categories: [],
    servicePackages: [],
    checkInCard: false,
    checkInApp: false,
    hansefitCard: false,
  },
};
