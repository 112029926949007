import React, { ReactNode, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import deTranslations from '../data/translations/de.json';
import enTranslations from '../data/translations/en.json';
import { Term } from '../models/Term';
import { getLanguage } from '../utils/language.utils';
import axios from 'axios';

export interface IntlProviderLayoutProps {
  children: ReactNode;
}

const IntlProviderLayout: React.FC<IntlProviderLayoutProps> = ({ children }) => {
  const language = getLanguage();
  const [terms, setTerms] = useState<Term>({});

  useEffect(() => {
    if (process.env.REACT_APP_TRANSLATE === 'remote' && window.location.hostname !== 'localhost') {
      (async function () {
        const termsResponse = await axios.get<Term>(
          process.env.REACT_APP_TRANSLATIONS_ENDPOINT + `po?language=${language}&project=SDP`,
        );

        if (Object.keys(terms).length === 0) {
          setTerms(termsResponse.data);
        }
      })();
    } else {
      if (language === 'de') {
        setTerms(deTranslations);
      }
      if (language === 'en') {
        setTerms(enTranslations);
      }
    }
  }, [language, terms]);

  return (
    <>
      {Object.keys(terms).length > 0 && (
        <IntlProvider locale={language} messages={terms} onError={() => undefined}>
          {children}
        </IntlProvider>
      )}
    </>
  );
};

export default IntlProviderLayout;
