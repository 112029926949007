import { styled, Typography } from '@mui/material';
import { withTransientProps } from '../../data/theme/withTransientProps';

export const EmptyResultsContainer = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 143px;
  padding-left: 47px;
  padding-right: 47px;
  gap: 12px;

  //@media (max-width: 768px) {
  //  background: #fff;
  //  margin-top: -48px;
  //}

  ${props => props.theme.breakpoints.down('lg')} {
    height: calc(100vh - 274px);
  }
  ${props => props.theme.breakpoints.down('sm')} {
    height: calc(100vh - 262px);
  }
`;

export const EmptyResultsTitle = styled(Typography)`
  color: #002a80;
  font-family: Roboto-Black;
  font-size: 15px;
  text-transform: uppercase;
  text-align: center;
`;

export const EmptyResultsText = styled(Typography)`
  color: #002a80;
  font-family: Roboto-Regular;
  font-size: 12px;
  color: #1f2126;
  text-align: center;
`;

export const Title = styled('span')`
  height: 26px;
  font-family: 'Roboto-Black';
  font-size: 20px;
  font-weight: 900;
  line-height: 1.3;
  letter-spacing: 0.25px;
  text-transform: uppercase;
  color: #1f2126;
`;

export const Container = styled('span', withTransientProps)<{ hasLocation: boolean }>`
  display: flex;
  flex-direction: column;
  position: fixed;
  height: ${props =>
    props.hasLocation ? 'calc(100% - 318px - 52px)' : 'calc(100% - 260px - 52px)'};
  width: 400px;
  top: ${props => (props.hasLocation ? '318px' : '260px')};
  background-color: white;

  ${props => props.theme.breakpoints.down('lg')} {
    width: 100%;
    top: 237px;
    height: calc(100% - 289px);
  }

  ${props => props.theme.breakpoints.down('md')} {
    width: 100%;
    top: 237px;
    height: calc(100% - 276px);
  }
`;

export const Header = styled('span')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 51px;
  border-top: 1px solid #d8d8d8;
  padding: 0 30px;

  ${props => props.theme.breakpoints.down('lg')} {
    padding: 0 16px;
  }
`;
