import { CaseReducer, createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { StudioModel } from '../../models/StudioModel';
import { initialState } from './initialState';
import { StudioFilter, StudioSortType, StudioState } from './interfaces';
import { AutocompleteOption, AutocompleteOptionType } from '../../models/AutocompleteOption';
import { getFilteredStudios, getFilterToStateFilter, sortStudiosBySortType } from './studio.utils';
import { Category } from '../../models/Category';
import studiosDe from '../../data/partners/de/partners.json';
import studiosEn from '../../data/partners/en/partners.json';
import { getLanguage } from '../../utils/language.utils';

const setHover: CaseReducer<StudioState, PayloadAction<string | null>> = (state, action) => {
  state.hover = action.payload;
};

const setCategories: CaseReducer<StudioState, PayloadAction<Category[]>> = (state, action) => {
  state.categories = action.payload;
};

const setSort: CaseReducer<StudioState, PayloadAction<StudioSortType>> = (state, action) => {
  state.sort = action.payload;
};

const setVisibleStudios: CaseReducer<StudioState, PayloadAction<StudioModel[]>> = (
  state,
  action,
) => {
  state.visibleStudios = action.payload;
};

const setQuery: CaseReducer<StudioState, PayloadAction<string>> = (state, action) => {
  state.query = action.payload;
};

const setRadiusFilterDisabled: CaseReducer<StudioState, PayloadAction<boolean>> = (
  state,
  action,
) => {
  state.radiusFilterDisabled = action.payload;
};

const setRadiusFilterValue: CaseReducer<StudioState, PayloadAction<number>> = (state, action) => {
  state.radiusFilterValue = action.payload;
};

const sortStudios: CaseReducer<
  StudioState,
  PayloadAction<{ sort: StudioSortType; selectedLocation: AutocompleteOption }>
> = (state, action) => {
  const { sort, selectedLocation } = action.payload;
  state.visibleStudios = sortStudiosBySortType(
    current(state.visibleStudios),
    sort,
    selectedLocation,
  );
};

const filterStudioById: CaseReducer<StudioState, PayloadAction<{ publicId: string }>> = (
  state,
  action,
) => {
  const { publicId } = action.payload;
  const studio = current(state).visibleStudios.find(studio => studio.publicId === publicId);

  if (studio) {
    state.visibleStudios = [studio];
  }
};

const filterStudiosByQuery: CaseReducer<
  StudioState,
  PayloadAction<{
    query: string;
    type: AutocompleteOptionType;
    sort: StudioSortType;
    filter: StudioFilter;
    radius: number;
    selectedLocation?: AutocompleteOption;
  }>
> = (state, action) => {
  const { query, sort, selectedLocation, type, filter, radius } = action.payload;
  const language = getLanguage();
  const studios = getFilteredStudios({
    studios: language === 'de' ? (studiosDe as StudioModel[]) : (studiosEn as StudioModel[]),
    filter,
    query,
    type,
    radius,
    selectedLocation,
  });

  state.visibleStudios = selectedLocation
    ? sortStudiosBySortType(studios, sort, selectedLocation)
    : studios;
};

const clearSearchFilters: CaseReducer<StudioState> = state => {
  state.visibleStudios = [];

  state.visibleStudios = state.visibleStudios.map(studio => {
    studio.distanceFromPosition = 0;

    return studio;
  });
  state.query = '';
  state.radiusFilterValue = 100;
  state.radiusFilterDisabled = true;
};

const selectStudio: CaseReducer<StudioState, PayloadAction<StudioModel>> = (state, action) => {
  state.selected = action.payload;
};

const setSelectedStudioModalOpen: CaseReducer<StudioState, PayloadAction<boolean>> = (
  state,
  action,
) => {
  state.selectedStudioModalOpen = action.payload;
};

const unselectStudio: CaseReducer<StudioState> = state => {
  state.selected = null;
};

const applyFilters: CaseReducer<
  StudioState,
  PayloadAction<{ memberships: number[]; checkInsAndServices: string[]; categories: number[] }>
> = (state, action) => {
  state.filter = getFilterToStateFilter(action.payload);
};

const resetFilters: CaseReducer<StudioState> = state => {
  state.filter = { ...initialState.filter };
};

export const studioSlice = createSlice({
  name: 'studios',
  initialState,
  reducers: {
    setQuery,
    setRadiusFilterDisabled,
    setRadiusFilterValue,
    sortStudios,
    filterStudiosByQuery,
    filterStudioById,
    clearSearchFilters,
    selectStudio,
    setSelectedStudioModalOpen,
    unselectStudio,
    applyFilters,
    resetFilters,
    setHover,
    setVisibleStudios,
    setSort,
    setCategories,
  },
});

export const { name, actions } = studioSlice;

export default studioSlice.reducer;
