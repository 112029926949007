import { Autocomplete, IconButton, styled, Tabs, Typography } from '@mui/material';
import { withTransientProps } from '../../data/theme/withTransientProps';

export const ToggleButton = styled(IconButton, withTransientProps)<{ $isOpen: boolean }>`
  height: 34px;
  width: 34px;
  position: relative;
  right: ${props => (props.$isOpen ? '-4px' : '-8px')};
  align-self: flex-start;
  justify-self: flex-end;
  ${props => (props.$isOpen ? '' : 'transform: rotate(180deg);')}
  transition: 0.5s all ease-in;

  ${props => props.theme.breakpoints.down('lg')} {
    display: none;
  }
`;

export const SidebarContainer = styled('div', withTransientProps)<{
  $isOpen: boolean;
  $showList: boolean;
  $hasLocation: boolean;
  $listTabOpen: boolean;
}>`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: ${props => (props.$isOpen ? '0' : '-345px')};
  z-index: 2;
  width: 400px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);

  background: white;
  transition: left 0.5s;

  ${props => props.theme.breakpoints.up('lg')} {
    height: ${props => (props.$showList ? '100%' : props.$hasLocation ? '260px' : '220px')};
  }
  
  ${props => props.theme.breakpoints.down('lg')} {
    width: 100%;
    position: fixed;
    top: 50px;
    height: ${props => (props.$listTabOpen ? 'calc(100vh - 102px)' : 'auto')};
  }
  
  ${props => props.theme.breakpoints.down('sm')} {
    height: ${props => (props.$listTabOpen ? 'calc(100vh - 90px)' : 'auto')};
  }
`;

export const FilterContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  padding-top: 19px;
  padding-right: 20px;
  background-color: white;
  ${props => props.theme.breakpoints.down('lg')} {
    padding: 0 16px;
  }
`;
export const FilterTitle = styled(Typography)`
  font-family: 'NutmegHeadline-Ultra', sans-serif;
  font-size: 24px;
  font-weight: 900;
  letter-spacing: 0.48px;
  text-transform: uppercase;
  color: #00194d;
  padding-top: 20px;
  padding-bottom: 22px;
  ${props => props.theme.breakpoints.down('lg')} {
    padding-top: 12px;
    padding-bottom: 0;
  }
`;

export const FilterForm = styled('div', withTransientProps)<{ $isOpen: boolean }>`
  display: ${props => (props.$isOpen ? 'flex' : 'none')};
  align-items: flex-start;
  flex-direction: column;
  gap: 6px;
  ${props => props.theme.breakpoints.down('lg')} {
    gap: 16px;
    margin-bottom: 12px;
  }
`;

export const FilterRow = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const StyledAutocomplete = styled(Autocomplete)`
  width: 300px;

  ${props => props.theme.breakpoints.down('lg')} {
    width: 100%;
  }

  & input {
    font-family: Roboto-Regular;
    font-size: 12px;
    color: #1f2126;
    height: 24px;

    ::placeholder {
      color: #6b7986;
      opacity: 1;
    }
  }

  .MuiAutocomplete-endAdornment button {
    visibility: visible;
  }
`;

export const FilterButton = styled(IconButton)`
  margin-left: 22px;
  position: relative;
  width: 30px;
  height: 33px;
`;

export const FilterIcon = styled('img')`
  width: 24px;
  height: 24px;
`;

export const ActiveFilter = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #e50151;
  color: white;
  width: 18px;
  height: 18px;
  font-size: 12px;
  font-family: Roboto-Regular;
  position: absolute;
  border-radius: 50%;
  left: 20px;
  top: -4px;
`;

export const TopContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const MobileTabs = styled(Tabs)`
  width: 100%;
  height: 40px;
  opacity: 1;
  background-color: #f8f8f8;
  font-family: Roboto-Medium;
  font-size: 14px;

  & .MuiTabs-indicator {
    background-color: #003bb3;
  }

  & .MuiTab-root {
    color: #1f2126;
  }

  & .MuiButtonBase-root.MuiTab-root.Mui-selected {
    color: #003bb3;
    font-weight: 500;
  }
`;
