export const getItemBackgroundColor = (key: number): string => {
  switch (key) {
    case 0: {
      return '#00194D';
    }
    case 1: {
      return '#003BB3';
    }
    case 2: {
      return '#002A80';
    }

    default:
      return '#212429';
  }
};

export const getFontWeight = (key: number): number => {
  return key > 2 ? 400 : 400;
};
