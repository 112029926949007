import { RootState } from '../store';

export const getDesktopMenu = (state: RootState) => state.page.menu.desktop;

export const getMobileMenu = (state: RootState) => state.page.menu.mobile;

export const getSidebar = (state: RootState) => state.page.sidebar.open;

export const PageSelectors = {
  getDesktopMenu,
  getMobileMenu,
  getSidebar,
};
