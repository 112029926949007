import { RadiusLabel, RadiusSlider, SliderContainer } from './Slider.styles';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { StudioSelectors } from '../../store/studio/studio.selectors';
import { StudioActions } from '../../store/studio';
import { MapSelectors } from '../../store/map/map.selectors';
import { MapActions } from '../../store/map';

const Slider = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const radiusFilterDisabled = useSelector(StudioSelectors.getRadiusFilterDisabled);
  const radius = useSelector(StudioSelectors.getRadiusFilterValue);
  const studios = useSelector(StudioSelectors.getVisibleStudios);
  const selectedStudio = useSelector(StudioSelectors.getSelected);
  const [sliderValue, setSliderValue] = useState<number>(100);
  const query = useSelector(StudioSelectors.getSearchQuery);
  const sort = useSelector(StudioSelectors.getSort);
  const filter = useSelector(StudioSelectors.getFilter);
  const selectedLocation = useSelector(MapSelectors.getSelectedLocation);

  const handleSliderChange = (e: Event, value: number | number[]) => {
    setSliderValue(value as number);
  };

  useEffect(() => {
    setSliderValue(radius);
  }, [radius]);

  const handleSliderChangeCommitted = () => {
    if (selectedLocation) {
      dispatch(StudioActions.setRadiusFilterValue(sliderValue));
      dispatch(
        StudioActions.filterStudiosByQuery({
          query,
          type: selectedLocation.type,
          sort,
          radius: sliderValue,
          filter,
          selectedLocation,
        }),
      );

      dispatch(
        MapActions.setMapBounds({
          center: { lat: selectedLocation.lat, lng: selectedLocation.lng },
          distance: sliderValue,
        }),
      );
    }
  };

  return (
    <SliderContainer>
      <RadiusLabel variant="caption">
        {radiusFilterDisabled || (!!selectedStudio && studios.length > 1)
          ? intl.formatMessage({
              id: 'sidebar.search_radius_title',
              defaultMessage: 'Search radius',
            })
          : intl.formatMessage({
              id: 'sidebar.search_radius_title',
              defaultMessage: 'Search radius',
            }) +
            ' (' +
            radius +
            ' km)'}
      </RadiusLabel>
      <RadiusSlider
        disabled={radiusFilterDisabled || (!!selectedStudio && studios.length >= 1)}
        value={sliderValue}
        min={1}
        step={1}
        max={100}
        size={'small'}
        onChange={handleSliderChange}
        onChangeCommitted={handleSliderChangeCommitted}
        valueLabelDisplay="auto"
        aria-labelledby="radius-slider"
      />
    </SliderContainer>
  );
};

export default Slider;
