import React from 'react';
import LocationIcon from '../../assets/icons/location-blue.svg';
import { useDispatch, useSelector } from 'react-redux';
import { MapActions } from '../../store/map';
import { StudioActions } from '../../store/studio';
import { useIntl } from 'react-intl';
import { AutocompleteOptionType } from '../../models/AutocompleteOption';
import { StudioSelectors } from '../../store/studio/studio.selectors';
import { MapSelectors } from '../../store/map/map.selectors';
import { LocationButtonContainer } from './StudioSearchresults.styles';
import { initialState } from '../../store/studio/initialState';

const LocationButton = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const radius = useSelector(StudioSelectors.getRadiusFilterValue);
  const sort = useSelector(StudioSelectors.getSort);
  const studios = useSelector(StudioSelectors.getVisibleStudios);
  const filter = useSelector(StudioSelectors.getFilter);
  const radiusFilterDisabled = useSelector(StudioSelectors.getRadiusFilterDisabled);
  const myLocation = useSelector(MapSelectors.getMyLocation);
  const filterTouched = JSON.stringify(filter) !== JSON.stringify(initialState.filter);
  const showList = filterTouched || !radiusFilterDisabled || studios.length === 0;
  const handleSetLocation = () => {
    if (myLocation) {
      dispatch(
        StudioActions.setQuery(
          intl.formatMessage({
            id: 'my_location',
            defaultMessage: 'Show my location on map',
          }),
        ),
      );
      dispatch(
        StudioActions.filterStudiosByQuery({
          query: '',
          type: AutocompleteOptionType.CurrentLocation,
          sort,
          radius,
          filter,
          selectedLocation: myLocation,
        }),
      );

      dispatch(
        MapActions.setMapBounds({
          center: { lat: myLocation.lat, lng: myLocation.lng },
          distance: radius,
        }),
      );

      dispatch(StudioActions.setRadiusFilterDisabled(false));
      dispatch(MapActions.setSelectedLocation(myLocation));
      dispatch(StudioActions.unselectStudio());
    }
  };

  return (
    <LocationButtonContainer onClick={handleSetLocation} $showList={showList}>
      <img src={LocationIcon} alt={''} />
      <span>
        {intl.formatMessage({
          id: 'query.show_my_location',
          defaultMessage: 'Show my location on map',
        })}
      </span>
    </LocationButtonContainer>
  );
};

export default LocationButton;
