// Sidebar

import { makeStyles } from '@material-ui/core';

export const LocationIconStyle = { color: '#15adf8', width: '16px', height: '22px' };

// Studio Searching Result

export const selectionStyles = makeStyles(() => ({
  radio: {
    '&$checked': {
      color: '#003bb3 !important',
    },
  },
  checked: {},
  formControl: {
    '&': {
      display: 'flex !important',
      flexDirection: 'row !important',
      width: '100px',
      alignItems: 'flex-end',
      justifyContent: 'space-evenly',
    },
    '& .MuiSelect-select.MuiSelect-select': {
      fontFamily: 'Roboto-Bold',
      fontSize: '12px',
      lineHeight: '1.5',
      letterSpacing: '0.11px',
    },
  },
}));
