import { Category } from '../../models/Category';
import { CheckInOption } from '../../models/CheckIn';

export const getResolvedCheckIns = (checkIns: string[], formatMessage: any): string[] => {
  return checkIns.map(checkIn => {
    switch (checkIn) {
      case CheckInOption.App: {
        return formatMessage({
          id: 'check_in.app',
          defaultMessage: 'Check-in with app',
        });
      }
      case CheckInOption.Card: {
        return formatMessage({
          id: 'check_in.card',
          defaultMessage: 'Check-in with card',
        });
      }

      case CheckInOption.Issuing: {
        return formatMessage({
          id: 'check_in.issuing',
          defaultMessage: 'Issuing of membership cards',
        });
      }
      case CheckInOption.Twogether: {
        return formatMessage({
          id: 'check_in.twogether',
          defaultMessage: 'Twogether training program',
        });
      }
      default: {
        return checkIn;
      }
    }
  });
};

export const getResolvedCategories = (
  selectedCategories: number[],
  categories: Category[],
): string[] => {
  const resolvedCategories = selectedCategories.map(categoryId => {
    const foundCategory = categories.find(category => category.id === categoryId);
    if (foundCategory) {
      return foundCategory.shortDescription as string;
    }
    return '';
  });

  return resolvedCategories;
};

export const getSelectValue = (
  selectedValues: string[],
  maxLength: number,
  additionalTranslation: string,
): string => {
  const sumLength = selectedValues.join(', ').trim().length;

  if (sumLength < maxLength) {
    return selectedValues.join(', ').trim();
  } else {
    const outputArray: string[] = [];

    selectedValues.every((category, idx) => {
      if (category && outputArray.join(', ').length + category?.length < maxLength) {
        outputArray.push(category);
      } else {
        return false;
      }

      return true;
    });

    const delta = selectedValues.length - outputArray.length;
    if (delta > 0) {
      return `${outputArray.join(', ').trim()} + ${delta} ${additionalTranslation}`.trim();
    } else {
      return outputArray.join(', ').trim();
    }
  }
};
