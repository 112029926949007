import { StudioModel } from '../../models/StudioModel';
import { Category } from '../../models/Category';

export interface StudioState {
  visibleStudios: StudioModel[];
  categories: Category[];
  query: string;
  radiusFilterDisabled: boolean;
  radiusFilterValue: number;
  hover: string | null;
  selected: StudioModel | null;
  sort: StudioSortType;
  filter: StudioFilter;
  selectedStudioModalOpen: boolean;
}

export interface StudioFilter {
  categories: number[];
  servicePackages: number[];
  checkInCard: boolean;
  checkInApp: boolean;
  hansefitCard: boolean;
}

export enum StudioSortType {
  Distance,
  Alphabetical,
}
