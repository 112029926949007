import sortBy from 'lodash.sortby';
import { StudioModel } from '../../models/StudioModel';
import { StudioFilter, StudioSortType } from './interfaces';
import { GeolibInputCoordinates } from 'geolib/es/types';
import { getDistance } from 'geolib';
import { AutocompleteOption, AutocompleteOptionType } from '../../models/AutocompleteOption';
import { CheckInOption } from '../../models/CheckIn';
import { initialState } from './initialState';
import { Category } from '../../models/Category';
import { UserMemberships } from '../../models/user_membership';

export const checkinImageBasePath = 'images/checkin_logos/';

export const sortStudiosBySortType = (
  studios: StudioModel[],
  sort: StudioSortType,
  selectedLocation: AutocompleteOption,
): StudioModel[] => {
  const studiosWithDistance = studios.map(studio => {
    const { coordLat, cordLong } = studio;
    return {
      ...studio,
      distanceFromPosition: getDistanceInKilometers(
        { lat: coordLat, lng: cordLong },
        { lat: selectedLocation.lat, lng: selectedLocation.lng },
      ),
    };
  });
  return sort === StudioSortType.Distance
    ? sortBy(studiosWithDistance, 'distanceFromPosition')
    : sortBy(studiosWithDistance, 'studioName');
};

export const getDistanceInKilometers = (
  firstPoint: GeolibInputCoordinates,
  secondPoint: GeolibInputCoordinates,
): number => {
  return parseFloat((getDistance(firstPoint, secondPoint) / 1000).toFixed(0));
};

export const isStudioValid = (studio: StudioModel) => {
  return (
    !!studio.publicId &&
    !!studio.coordLat &&
    !!studio.cordLong &&
    studio.studioVisibility &&
    studio.published
  );
};

export const getFilteredStudios = (params: {
  studios: StudioModel[];
  filter: StudioFilter;
  query: string;
  type: AutocompleteOptionType;
  radius: number;
  selectedLocation?: AutocompleteOption;
}): StudioModel[] => {
  const { studios, filter, query, type, selectedLocation, radius } = params;
  const { checkInApp, checkInCard, hansefitCard, servicePackages, categories } = filter;
  const maxDistance = radius * 1000;
  const formattedServicePackages = servicePackages.map(service => service.toString());
  const servicePackagesWithoutMemberships = formattedServicePackages.filter(
    servicePackage =>
      servicePackage !== UserMemberships.basic.toString() &&
      servicePackage !== UserMemberships.pro.toString() &&
      servicePackage !== UserMemberships.best.toString(),
  );

  const isBasic = formattedServicePackages.indexOf(UserMemberships.basic.toString()) !== -1;
  const isPro = formattedServicePackages.indexOf(UserMemberships.pro.toString()) !== -1;
  const isBest = formattedServicePackages.indexOf(UserMemberships.best.toString()) !== -1;
  const isFilteringByPackage = isBasic || isPro || isBest;
  const studiosReducedByServicePackage = isFilteringByPackage
    ? studios.filter(studio => {
        const hasBasic =
          Object.keys(studio.servicePackages).indexOf(UserMemberships.basic.toString()) !== -1;
        const hasPro =
          Object.keys(studio.servicePackages).indexOf(UserMemberships.pro.toString()) !== -1;
        const hasBest =
          Object.keys(studio.servicePackages).indexOf(UserMemberships.best.toString()) !== -1;

        return (
          (isBasic && hasBasic) ||
          (isPro && (hasPro || hasBasic)) ||
          (isBest && (hasBasic || hasPro || hasBest))
        );
      })
    : studios;

  return studiosReducedByServicePackage.filter(studio => {
    if (!isStudioValid(studio)) {
      return false;
    }

    const isCity = type === AutocompleteOptionType.City;
    const isStreet = type === AutocompleteOptionType.Street;
    const isCurrentLocation = type === AutocompleteOptionType.CurrentLocation;
    const isPrediction = type === AutocompleteOptionType.Prediction;
    const hasStudioNameMatch =
      type === AutocompleteOptionType.Studio &&
      (query === '' || studio.studioName?.toLowerCase().includes(query.toLowerCase()));

    const distance = selectedLocation
      ? getDistance(
          { lat: selectedLocation.lat, lng: selectedLocation.lng },
          {
            latitude: studio.coordLat,
            longitude: studio.cordLong,
          },
        )
      : undefined;

    const isInRange = (distance && distance < maxDistance) || !distance;

    const checkCheckInApp = (checkInApp && studio.checkInApp) || !checkInApp;
    const checkCheckInCard = (checkInCard && studio.checkInCard) || !checkInCard;
    const checkHansefitCard = (hansefitCard && studio.hansefitCard) || !hansefitCard;

    const matchCategoryPrimary =
      (categories.length > 0 && categories.indexOf(studio.categoryPrimary) !== -1) ||
      (categories.length === 0 && studio.categoryPrimary) ||
      (categories.length === 0 && !studio.categoryPrimary);

    const matchCategories = categories.some(element => studio.categories.includes(element));

    const matchServicePackages =
      (servicePackagesWithoutMemberships.length > 0 &&
        Object.keys(studio.servicePackages).filter(service =>
          servicePackagesWithoutMemberships.includes(service),
        ).length > 0) ||
      servicePackagesWithoutMemberships.length === 0;

    return (
      (isCity || isStreet || isCurrentLocation || hasStudioNameMatch || isPrediction) &&
      isInRange &&
      checkCheckInApp &&
      checkCheckInCard &&
      checkHansefitCard &&
      (matchCategoryPrimary || matchCategories) &&
      matchServicePackages
    );
  });
};

export const getFilterToStateFilter = (params: {
  memberships: number[];
  checkInsAndServices: string[];
  categories: number[];
}): StudioFilter => {
  const { checkInsAndServices, categories, memberships } = params;
  const servicesPackages = [];

  const filterState: StudioFilter = {
    ...initialState.filter,
  };

  filterState.checkInApp = checkInsAndServices.indexOf(CheckInOption.App) !== -1;

  filterState.checkInCard = checkInsAndServices.indexOf(CheckInOption.Card) !== -1;

  filterState.hansefitCard = checkInsAndServices.indexOf(CheckInOption.Issuing) !== -1;

  if (checkInsAndServices.indexOf(CheckInOption.Twogether) !== -1) {
    servicesPackages.push(18);
  }

  filterState.categories = categories;

  filterState.servicePackages = [...servicesPackages, ...memberships];

  return filterState;
};

export const getCategoryById = (categories: Category[], id: number): Category => {
  const category = categories.find(category => category.id === id);

  return category
    ? category
    : {
        id: 0,
        shortDescription: null,
        iconUrlContour: '',
        iconUrlFilled: '',
        iconUrlCircle: '',
        iconUrlSelected: '',
        iconUrlWithoutCircle: '',
        imageUrl: '',
        mapMarkerUrlInactive: '',
        longDescription: '',
        mapMarkerUrlActive: '',
        mapMarkerUrlSelected: '',
      };
};

export const getResolvedCategories = (studio: StudioModel, categories: Category[]) => {
  const combinedCategories = [
    studio.categoryPrimary,
    ...(studio.categories ? studio.categories : []),
  ].map(combinedCategory => {
    const foundCategory = categories.find(category => category.id === combinedCategory);
    if (foundCategory) {
      return foundCategory.shortDescription;
    }
    return '';
  });

  return combinedCategories.join(', ');
};

export const isMembershipDisabled = (
  membership: UserMemberships,
  selectedMemberships: UserMemberships[],
) => {
  if (selectedMemberships.indexOf(membership) === -1 && selectedMemberships.length > 0) {
    return true;
  }

  return false;
};
