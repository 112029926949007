import { RootState } from '../store';

const getRadiusFilterValue = (state: RootState) => state.studios.radiusFilterValue;

const getRadiusFilterDisabled = (state: RootState) => state.studios.radiusFilterDisabled;

const getSearchQuery = (state: RootState) => state.studios.query;

const getVisibleStudios = (state: RootState) => state.studios.visibleStudios;

const getCategories = (state: RootState) => state.studios.categories;

const getHover = (state: RootState) => state.studios.hover;

const getSelected = (state: RootState) => state.studios.selected;

const getSort = (state: RootState) => state.studios.sort;

const getFilter = (state: RootState) => state.studios.filter;

const getSelectedStudioModalOpen = (state: RootState) => state.studios.selectedStudioModalOpen;

export const StudioSelectors = {
  getRadiusFilterValue,
  getRadiusFilterDisabled,
  getSearchQuery,
  getVisibleStudios,
  getHover,
  getSelected,
  getSort,
  getFilter,
  getCategories,
  getSelectedStudioModalOpen
};
