import { Box, Link, styled } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { withTransientProps } from '../../data/theme/withTransientProps';

export const Container = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9;
  height: 70px;
  background-image: linear-gradient(to bottom, #ffffff, #fbfbfb, #f7f7f7, #f4f4f4, #f0f0f0);

  ${props => props.theme.breakpoints.down('lg')} {
    height: 59px;
  }

  ${props => props.theme.breakpoints.down('md')} {
    height: 50px;
  }
`;

export const Logo = styled('img')`
  align-self: flex-start;
  z-index: 2;
  ${props => props.theme.breakpoints.down('md')} {
    height: 51px;
  }
`;

export const TopBar = styled('div')(({ theme }) => ({
  backgroundColor: '#fff',
  width: '100%',
  height: '55px',
  zIndex: 1,
  position: 'absolute',
  boxShadow: '0 5px 20px 0 rgba(0, 0, 0, 0.1)',
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
  [theme.breakpoints.down('lg')]: {
    height: '59px',
  },
  [theme.breakpoints.down('md')]: {
    height: '50px',
  },
}));

export const MobileMenuIconContainer = styled('div')(({ theme }) => ({
  height: 'auto',
  position: 'absolute',
  top: '10px',
  right: '8px',
  zIndex: 9,
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
}));

export const MobileMenuContainer = styled('div')(({ theme }) => ({
  backgroundColor: '#003bb3',
  zIndex: 999,
  height: 'calc(100vh - 55px)',
  position: 'absolute',
  right: '0',
  marginTop: '55px',
  [theme.breakpoints.down('lg')]: {
    maxWidth: '428px',
    width: '100%',
    marginTop: '59px',
    height: 'calc(100vh - 59px)',
  },
  [theme.breakpoints.down('md')]: {
    marginTop: '50px',
    height: 'calc(100vh - 50px)',
  },
  [theme.breakpoints.up('lg')]: {
    width: '428px',
  },
}));

export const MobileMenu = styled(Box)`
  padding: 0;
  margin: 0;
`;

export const MobileMenuItem = styled(Box, withTransientProps)<{
  $backgroundColor: string;
  $isLast: boolean;
}>`
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: ${props => props.$backgroundColor};
  border-bottom: ${props =>
    props.$backgroundColor === '#212429' && !props.$isLast ? '1px solid #d8d8d8' : 'inherit'};

  > a {
    text-transform: uppercase;
    color: #fff;
    line-height: 50px;
    font-size: 14px;
    text-decoration: none;
    font-family: Arial, Helvetica, sans-serif;
    padding-left: 5%;
    padding-right: 14px;
    height: 50px;
  }

  > .dropdown {
    display: block;
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style-type: none;

    > li {
      background-color: #2b2d32;
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 50px;
      > a {
        font-size: 14px;
        letter-spacing: 0;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 500;
        color: #fff;
        text-align: left;
        text-decoration: none;
        position: relative;
        overflow: hidden;
        transition: background-color 0.5s, border-color 0.5s, 0.5s;
        padding: 0 0 0 50px;
        vertical-align: center;
      }
    }
  }
`;

export const MenuItem = styled(Box, withTransientProps)<{
  $backgroundColor: string;
  $fontWeight: number;
  $isLast: boolean;
}>`
  display: block;
  transition-duration: 0.5s;
  position: relative;
  text-align: center;
  transition: 0.4s opacity ease-in-out !important;
  background-color: ${props => props.$backgroundColor};
  font-weight: ${props => props.$fontWeight};
  &:hover {
    cursor: pointer;
  }
  &:hover > .dropdown,
  .menuItem .dropdown:hover {
    visibility: visible;
    opacity: 1;
    display: block;
  }

  > a {
    padding: 0 30px;
    display: flex;
    justify-content: center;
    color: white;
    height: 70px;
    font-family: 'Arial';
    letter-spacing: 1px;
    font-style: normal;
    font-size: 13px;
    align-items: center;
    line-height: 1;
    text-decoration: ${props => (props.$isLast ? 'underline' : 'none')};
    -webkit-font-smoothing: subpixel-antialiased;

    ${props => props.theme.breakpoints.down('xl')} {
      padding: 0 24px;
    }

    @media (max-width: 1338px) {
      padding: 0 12px;
    }

    span {
      text-transform: uppercase;
    }
  }

  > a:hover {
    text-decoration: underline;
  }

  > .dropdown {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    transition: all 0.2s ease-in-out;
    background-color: green;
    width: 100%;
    padding: 0;
    > li {
      list-style-type: none;
      width: 100%;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 70px;
      cursor: pointer;
      background-color: #212429;
      &:hover {
        background-color: #5a5b5f;
      }
      > a {
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 400;
        color: #fff;
        font-size: 13px;
        text-decoration: none;
        text-transform: uppercase;
        height: 70px;
      }
    }
  }
`;

export const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
  height: 32px;
  width: 32px;
  &:hover {
    cursor: pointer
  },
`;

export const StyledMenuIcon = styled(MenuIcon)`
  cursor: pointer;
  height: 32px;
  width: 32px;
  &:hover {
    cursor: pointer
  },
`;

export const MenuItemLink = styled(Link, withTransientProps)<{ $fontWeight: number }>`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  font-weight: ${props => props.$fontWeight};
`;

export const MenuDesktop = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  listStyle: 'none',
  flexWrap: 'wrap',
  transition: 'opacity .8s cubic-bezier(.8,0,.25,1),transform .8s cubic-bezier(.8,0,.25,1)',
  margin: 0,
  padding: 0,
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
}));
