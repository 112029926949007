import { Box } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { PageSelectors } from '../../store/page/page.selectors';
import LogoImage from '../../assets/icons/hansefit-logo.svg';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Container,
  Logo,
  MenuDesktop,
  MenuItem,
  MenuItemLink,
  MobileMenu,
  MobileMenuContainer,
  MobileMenuIconContainer,
  MobileMenuItem,
  StyledCloseIcon,
  StyledMenuIcon,
  TopBar,
} from './Header.styles';
import { Menu } from '../../store/page/interfaces';
import { getFontWeight, getItemBackgroundColor } from './Header.utils';

export const Header = () => {
  const desktopMenu = useSelector(PageSelectors.getDesktopMenu);
  const mobilMenu = useSelector(PageSelectors.getMobileMenu);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [activeSubmenu, setActiveSubmenu] = useState<number>(-1);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleSubmenu = (id: number, childItems: Partial<Menu>[]) => {
    if (childItems && id !== activeSubmenu) {
      setActiveSubmenu(id);
    } else {
      setActiveSubmenu(-1);
    }
  };

  return (
    <>
      <Container>
        <Logo src={LogoImage} />
        <TopBar />
        <MobileMenuIconContainer>
          {menuOpen && <StyledCloseIcon onClick={toggleMenu} />}
          {!menuOpen && <StyledMenuIcon onClick={toggleMenu} />}
        </MobileMenuIconContainer>

        {menuOpen && (
          <MobileMenuContainer>
            <MobileMenu component={'ul'}>
              {mobilMenu?.items?.map((item, key, items) => {
                return (
                  <MobileMenuItem
                    key={key}
                    component={'li'}
                    $isLast={items.length - 1 === key}
                    $backgroundColor={getItemBackgroundColor(key)}>
                    <MenuItemLink
                      $fontWeight={item.ID === activeSubmenu ? 600 : 400}
                      href={item.url}
                      onClick={() => toggleSubmenu(item?.ID!, item?.child_items!)}>
                      <Box component="span">{item.title}</Box>
                      {item.child_items && (
                        <>
                          {item.ID === activeSubmenu ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </>
                      )}
                    </MenuItemLink>
                    {item.child_items && (
                      <ul
                        className="dropdown"
                        style={{ display: item.ID === activeSubmenu ? 'block' : 'none' }}>
                        {item.child_items.map((subItem, subKey) => {
                          return (
                            <li key={subKey}>
                              <a href={subItem.url}>{subItem.title}</a>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </MobileMenuItem>
                );
              })}
            </MobileMenu>
          </MobileMenuContainer>
        )}

        <MenuDesktop component={'ul'}>
          {desktopMenu?.items?.map((item, key, items) => {
            return (
              <MenuItem
                key={key}
                component={'li'}
                $isLast={items.length - 1 === key}
                $backgroundColor={getItemBackgroundColor(key)}
                $fontWeight={getFontWeight(key)}>
                <Box component={'a'} href={item.url}>
                  <Box component="span">{item.title}</Box>
                </Box>
                {item.child_items && (
                  <ul className="dropdown">
                    {item.child_items.map((subItem, subKey) => {
                      return (
                        <li key={subKey}>
                          <a href={subItem.url}>{subItem.title}</a>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </MenuItem>
            );
          })}
        </MenuDesktop>
      </Container>
    </>
  );
};
