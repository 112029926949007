import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, styled, Typography } from '@mui/material';

export const StyledFooter = styled('footer')`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 9;
  width: 100%;
  bottom: 0;
  height: 52px;
  position: fixed;
  ${props => props.theme.breakpoints.down('sm')} {
    height: 40px;
    
    
  }
`;

export const Container = styled('div')`
  background-color: #1f2126;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #a3acb6;
  flex: 1;
  position: relative;

  ${props => props.theme.breakpoints.down('md')} {
    justify-content: flex-start;
  }
`;

export const StyledText = styled(Typography)`
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: subpixel-antialiased;
  color: #87888a;
  ${props => props.theme.breakpoints.down('sm')} {
    font-size: 10px;
  }
`;

export const GeneralInfo = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  ${props => props.theme.breakpoints.down('md')} {
    margin-left: 20px;
  }
`;

export const SocialMedia = styled('div')`
  position: absolute;
  right: 106px;
  display: flex;
  flex-direction: row;

  ${props => props.theme.breakpoints.down('md')} {
    bottom: initial;
    right: 12px;
  }
  ${props => props.theme.breakpoints.down('sm')} {
    right: 20px;
    bottom: 12px;
  }
`;

export const StyledSocialMediaLink = styled(Link)`
  color: white;
  
  ${props => props.theme.breakpoints.down('sm')} {
    width: 18px;
  }
`;
export const StyledSocialMediaIcon = styled(FontAwesomeIcon)`
  padding: 0 15px;
  width: 18px;
  height: 18px;

  ${props => props.theme.breakpoints.down('sm')} {
    padding: 0 10px;
    width: 12px;
    height: 12px;
  }
`;

export const StyledLink = styled(Link)`
  height: auto;
  color: #87888a;
  transition-property: color, background-color, border-color;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  text-decoration: none;
  text-align: center;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: subpixel-antialiased;
  &:hover {
    color: #ffffff;
  }
  ${props => props.theme.breakpoints.down('sm')} {
    font-size: 10px;
  }
`;

export const Separator = styled('div')`
  width: 20px;
  text-align: center;
  font-size: 12px;
  padding-bottom: 3px;
`;
